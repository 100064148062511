import { createContext, useEffect, useReducer, useState } from 'react'

const initialState = {
  sdk: () => window.smartsupp,
}

export const ChatContext = createContext<any>(initialState)

const actions = {
  SET_SDK: (state: any, payload: any) => {
    state = Object.assign({}, state, { sdk: payload })
    return state
  },
  OPEN: (state: any) => {
    if (!state.sdk()) return
    state.sdk()('chat:open')
    return state
  },
  CLOSE: (state: any) => {
    if (!state.sdk()) return
    state.sdk()('chat:close')
    return state
  },
  SHOW: (state: any) => {
    if (!state.sdk()) return
    state.sdk()('chat:show')
    return state
  },
  HIDE: (state: any) => {
    if (!state.sdk()) return
    state.sdk()('chat:hide')
    return state
  },
  SET_LOCALE: (state: any, payload: string) => {
    if (!state.sdk()) return
    state.sdk()('language', payload)
    return state
  },
}

const reducer = (state: any, action: { type: string; payload?: any }) => {
  return actions[action.type](state, action.payload)
}

export const ChatProvider = ({ children }) => {
  const [chat, dispatch] = useReducer(reducer, initialState)
  const [providerValue, setProviderValue] = useState({ chat, dispatch })

  useEffect(() => {
    setProviderValue({ chat, dispatch })
  }, [chat])

  return (
    <ChatContext.Provider value={providerValue}>
      {children}
    </ChatContext.Provider>
  )
}
