import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { createContext, useEffect, useReducer, useState } from 'react'

const initialState = {
  open: false,
  id: null,
  lastUpdate: 0,
  variantsCustomizations: {
    open: false,
    customizations: [],
  },
}

export const CartContext = createContext<any>(initialState)

const actions = {
  SET_ID: (state: any, payload: string) => {
    state = Object.assign({}, state, { id: payload })
    return state
  },
  OPEN: (state: any) => {
    state = Object.assign({}, state, { open: true })
    return state
  },
  CLOSE: (state: any) => {
    state = Object.assign({}, state, { open: false })
    return state
  },
  UPDATE: (state: any) => {
    state = Object.assign({}, state, { lastUpdate: Date.now() })
    return state
  },
  OPEN_VARIANTS_CUSTOMIZATIONS: (state: any) => {
    state = Object.assign({}, state, {
      variantsCustomizations: Object.assign({}, state.variantsCustomizations, {
        open: true,
      }),
    })
    return state
  },
  CLOSE_VARIANTS_CUSTOMIZATIONS: (state: any) => {
    state = Object.assign({}, state, {
      variantsCustomizations: Object.assign({}, state.variantsCustomizations, {
        open: false,
      }),
    })
    return state
  },
  ADD_VARIANT_CUSTOMIZATION: (
    state: any,
    payload: { nodeId: string; value: string; timestamp: number }
  ) => {
    const variantsCustomizations =
      state.variantsCustomizations.customizations.concat({
        nodeId: payload.nodeId,
        value: payload.value,
        timestamp: payload.timestamp,
      })

    state = Object.assign({}, state, {
      variantsCustomizations: Object.assign({}, state.variantsCustomizations, {
        customizations: variantsCustomizations,
      }),
    })
    return state
  },
  REMOVE_VARIANT_CUSTOMIZATION: (
    state: any,
    payload: { nodeId: string; value: string; timestamp: number }
  ) => {
    const variantsCustomizations =
      state.variantsCustomizations.customizations.filter(
        (customization: { nodeId: string; value: string; timestamp: number }) =>
          customization.nodeId !== payload.nodeId &&
          customization.value !== payload.value &&
          customization.timestamp !== payload.timestamp
      )

    state = Object.assign({}, state, {
      variantsCustomizations: Object.assign({}, state.variantsCustomizations, {
        customizations: variantsCustomizations,
      }),
    })
    return state
  },
}

const reducer = (state: any, action: { type: string; payload?: any }) => {
  return actions[action.type](state, action.payload)
}

export const CartProvider = ({ children }) => {
  const [cart, dispatch] = useReducer(reducer, initialState)
  const [providerValue, setProviderValue] = useState({ cart, dispatch })

  useEffect(() => {
    setProviderValue({ cart, dispatch })
  }, [cart])

  useEffect(() => {
    if (!window) return
    if (!window.localStorage) return
    if (!window.localStorage.getItem('cartId')) return
    dispatch({ type: 'SET_ID', payload: window.localStorage.getItem('cartId') })
  }, [])

  useEffect(() => {
    if (!window) return
    if (!window.localStorage) return
    if (!cart.id) return
    window.localStorage.setItem('cartId', cart.id)
  }, [cart.id])

  const [createCart] = useMutation(gql`
    mutation cartCreate {
      cartCreate {
        cart {
          id
        }
      }
    }
  `)

  useEffect(() => {
    if (!window) return
    if (!window.localStorage) return
    if (window.localStorage.getItem('cartId')) return
    if (cart.id) return

    createCart().then(({ data }) => {
      dispatch({ type: 'SET_ID', payload: data.cartCreate.cart.id })
      return data
    })
  }, [cart.id, createCart])

  const [checkCart] = useLazyQuery(
    gql`
      query getCartId($cartId: ID!) {
        cart(id: $cartId) {
          id
        }
      }
    `,
    { variables: { cartId: cart.id }, ssr: false, fetchPolicy: 'network-only' }
  )

  useEffect(() => {
    if (!window) return
    if (!cart.id) return

    checkCart({ variables: { cartId: cart.id } }).then(({ data }) => {
      if (data?.cart?.id) return

      createCart().then(({ data }) => {
        dispatch({ type: 'SET_ID', payload: data.cartCreate.cart.id })
      })
    })
  }, [cart.id, checkCart, createCart])

  return (
    <CartContext.Provider value={providerValue}>
      {children}
    </CartContext.Provider>
  )
}
