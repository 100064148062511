import { createContext, useEffect, useReducer, useState } from 'react'

declare global {
  interface Window {
    fbq: any
  }
}

const initialState = {
  fbq: () => (window.fbq ? window.fbq : () => {}),
}

export const PixelContext = createContext<any>(initialState)

const actions = {
  SET_FBQ: (state: any, payload: any) => {
    state = Object.assign({}, state, { fbq: payload })
    return state
  },
  EVENT: (state: any, payload: { type: string; content: any }) => {
    state.fbq()('track', payload.type, payload.content)
    return state
  },
}

const reducer = (state: any, action: { type: string; payload?: any }) => {
  return actions[action.type](state, action.payload)
}

export const PixelProvider = ({ children }) => {
  const [fbq, dispatch] = useReducer(reducer, initialState)
  const [providerValue, setProviderValue] = useState({ fbq, dispatch })

  useEffect(() => {
    setProviderValue({ fbq, dispatch })
  }, [fbq])

  return (
    <PixelContext.Provider value={providerValue}>
      {children}
    </PixelContext.Provider>
  )
}
