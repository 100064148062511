/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

(window ).__rewriteFramesAssetPrefixPath__ = '';

import { GLOBAL_OBJ } from '@sentry/utils';

/* eslint-disable no-constant-condition */

const globalObj = GLOBAL_OBJ ;

globalObj.SENTRY_RELEASE = { id: 'd4bf85e9536b04e82775bfe62be417337a1dd112' };

// Enable module federation support (see https://github.com/getsentry/sentry-webpack-plugin/pull/307)
if ('') {
  const key = 'undefined' ? '@undefined' : '';
  globalObj.SENTRY_RELEASES = globalObj.SENTRY_RELEASES || {};
  globalObj.SENTRY_RELEASES[key] = { id: 'd4bf85e9536b04e82775bfe62be417337a1dd112' };
}

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://d86046b50ef14f8d8c092d21be44181d@o198001.ingest.sentry.io/5560626',
  tracesSampleRate: 0,
})
