import {
  ApolloClient,
  InMemoryCache,
  from,
  HttpLink,
  ApolloLink,
} from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'

const domain = process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN
const storefrontAccessToken =
  process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESSTOKEN
const endpointVersion = process.env.NEXT_PUBLIC_SHOPIFY_ENDPOINT_VERSION

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      CartCost: {
        keyFields: ['totalAmount'],
      },
    },
  }),
  link: from([
    new RetryLink({
      delay: {
        initial: 150,
        max: Infinity,
        jitter: true,
      },
      attempts: {
        max: 5,
        retryIf: (error) => !!error,
      },
    }),
    new ApolloLink((operation, forward) => {
      operation.setContext(({ headers }) => ({
        headers: {
          'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
          ...headers,
        },
      }))

      return forward(operation)
    }),
    new HttpLink({
      uri: `https://${domain}/api/${endpointVersion}/graphql.json`,
    }),
  ]),
})

export default client
