import { createContext, useEffect, useReducer, useState } from 'react'

declare global {
  interface Window {
    gtag: any
  }
}

const initialState = {
  gtag: () => window.gtag ? window.gtag : () => {},
}

export const GTagContext = createContext<any>(initialState)

const actions = {
  SET_GTAG: (state: any, payload: any) => {
    state = Object.assign({}, state, { gtag: payload })
    return state
  },
  EVENT: (state: any, payload: { type: string; content: any }) => {
    state.gtag()('event', payload.type, payload.content)
    return state
  },
}

const reducer = (state: any, action: { type: string; payload?: any }) => {
  return actions[action.type](state, action.payload)
}

export const GTagProvider = ({ children }) => {
  const [gtag, dispatch] = useReducer(reducer, initialState)
  const [providerValue, setProviderValue] = useState({ gtag, dispatch })

  useEffect(() => {
    setProviderValue({ gtag, dispatch })
  }, [gtag])

  return (
    <GTagContext.Provider value={providerValue}>
      {children}
    </GTagContext.Provider>
  )
}
