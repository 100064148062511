import '../styles/global.css'

import Head from 'next/head'
import { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import { GTagProvider } from '../providers/GTagContext'
import { ChatProvider } from '../providers/ChatContext'
import { CartProvider } from '../providers/CartContext'

import shopify from '../lib/shopify'
import dynamic from 'next/dynamic'
import { PixelProvider } from '../providers/PixelContext'
import 'core-js/actual/string/match-all'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

const Smartsupp = dynamic(() => import('../components/Smartsupp'))
const Analytics = dynamic(() => import('../components/Analytics'))
const HotJar = dynamic(() => import('../components/HotJar'))
const Pixel = dynamic(() => import('../components/Pixel'))
const PageView = dynamic(() => import('../components/PageView'))
const QueryAddToCart = dynamic(() => import('../components/QueryAddToCart'))

function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const canonicalUrl = useMemo(() => {
    return (
      process.env.NEXT_PUBLIC_SITE_URL +
      (router.asPath === '/' ? '' : router.asPath)
    ).split('?')[0]
  }, [router.asPath])

  return (
    <GTagProvider>
      <PixelProvider>
        <ChatProvider>
          <Head>
            <link rel="canonical" href={canonicalUrl} />
            <link rel="icon" href="/favicon.ico" />
          </Head>

          <Analytics />
          <Pixel />
          <HotJar />
          <Smartsupp />
          <PageView />

          <ApolloProvider client={shopify}>
            <CartProvider>
              <QueryAddToCart />
              <Component {...pageProps} />
            </CartProvider>
          </ApolloProvider>
        </ChatProvider>
      </PixelProvider>
    </GTagProvider>
  )
}

export default App
